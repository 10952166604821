import React from 'react'
import './about.css'
import ME from '../../assets/f9.jpg'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small> Less than one Year, Fresher</small>
            </article>

            <article className='about__card'>
              <FiUsers className='about__icon'/>
              <h5>Current status</h5>
              <small>Data Analyst at Pandya Software Service INC</small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Projects</h5>
              <small>5 with this portfolio website</small>
            </article>
          </div>

          <p>
          I am Fenil, a data analyst passionate about working with organisations that want to make effective use of their data; 
          from the first ideas around what you want to measure and how to capture data accurately, through data preparation, analysis and management, 
          all the way to impactful presentation of insights. I can support each step of the process to enable your organisation in making evidence-driven decisions or share insights with new audiences.
          </p>

          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About