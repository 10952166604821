import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/da2.jpg'
import AVTR2 from '../../assets/cs2.jpg'

// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';



const data = [
  {
    avatar: AVTR1,
    name: 'Data Analytics for Business',
    review: 'This program taught me to visualize past, present, and future patterns by linking and presenting information in meaningful ways. Data analytics offers users deeper insight and meaning of data sets by telling the story behind the information. This type of detailed and defined information enables one to predict trends effectively, understand the needs of customers, as well as make more informed business decisions.'
  },
  {
    avatar: AVTR2,
    name: 'Computer Science Engineering',
    review: 'This program taught me many subjects that can lead to different job fields. I was interested in database-related subjects, which are DBMS, RDBMS, Data Mining, SQL, and Cloud computing. After completion, I decided to get a specific degree in Data Analytics.'
  }
]


const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>What I have studied</h5>
      <h2>Education</h2>

      <Swiper className="container testimonials__container" 
      // install Swiper modules
      modules={[Pagination]} spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}>
        {
          data.map(({avatar, name, review}, index) => {
            return (
              <SwiperSlide key={index} className="testimonial">
              <div className="client__avatar">
                <img src={avatar}/>
              </div>
              <h5 className='client__name'>{name}</h5>
              <small className='client__review'>{review}</small>
            </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}

export default Testimonials