import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

const Services = () => {
  return (
    <section id='services'>
      <h5>What I have did</h5>
      <h2>Experience</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>Data Analyst Intern</h3>
            <h5>Windsor Regional Hospital, Windsor, ON</h5>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Solved different types of metrics referring different departments.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Extracted patient data in a Excel from Cerner System.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Created reports Using PoweBI.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Learned and applied proper ethical concerns.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Effectively communicated with the different team as per the project requirements.</p>
            </li>
          </ul>
        </article>
        {/* END OF UI/UX */}

        {/* WEB DEVELOPMENT */}
        <article className="service">
          <div className="service__head">
            <h3>Data Analyst</h3>
            <h5>Pandya Software Services inc, Ottawa, Canada</h5>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Develop requirements specifications, prepare business cases, coordinate development of and implement solutions.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Facilitate sessions with business users to acquire requirements for systems to be developed.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Documented new requirements and find opportunities for applying more efficient and effective solutions.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Assist in data preparation activities including exploration, cleansing, validation, integration, transformation, and aggregation of data.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Built insightful dashboards using PowerBI.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Provide recommendations for technical solutions based on best practices.</p>
            </li>
          </ul>
        </article>
        {/* END OF CONTENT CREATION */}

        {/* WEB DEVELOPMENT */}
        <article className="service">
          <div className="service__head">
            <h3>Data Analyst Intern</h3>
            <h5>Reliance Industries Ltd, Surat, Gujarat</h5>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Extracted database from the server using SQL queries and stored procedures, build ETL pipeline using Python</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Performed complex analyses of developmental, financial, and organizational database, Using Excel.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Participated in identification, analysis and interpretation of trends, patterns, correlations, and anomalies in complex datasets for the purpose of finding insights and making conclusions.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Understand data mapping and data flow within the supported systems.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>visualization of insights from customer data using Python, Tableau and SQL for decision making to improve the customer's involvement by 10%.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Provide recommendations for technical solutions based on best practices.</p>
            </li>
          </ul>
        </article>
        {/* END OF CONTENT CREATION */}
      </div>
    </section>
  )
}

export default Services