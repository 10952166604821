import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {SiDatacamp} from 'react-icons/si'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com/in/fenil98/" target="_blank"><BsLinkedin size={20}/></a>
        <a href="https://github.com/Fenil98" target="_blank"><FaGithub size={20}/></a>
        <a href="https://app.datacamp.com/profile/w0779094" target="_blank"><SiDatacamp size={20}/></a>
    </div>
  )
}

export default HeaderSocials